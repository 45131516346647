body.kr {
    background-color: #ffffff;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 12px;
    color: #4c4c4c;
}

.kr-purchase-result {
    display: flex;
    background-color: white;
}

.kr-purchase-result .container {
    border-bottom: none;
}

.kr-purchase-result .right-side {
    background-color: #fafafa;
    height: 850px;
    width: 994px;
    padding: 30px 50px;
    position: relative;
}

.kr-results {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 66px;
    height: 520px;
}

.right-side {
    overflow: hidden;
}

.kr-result-header {
    color: #686868;
    padding-left: 5px;
}

.kr-result-header p {
    font-family: "Nanum Gothic", sans-serif;
    font-size: 32px;
    line-height: 1.25em;
    margin: 0;
}

.kr-result-header .more-info {
    margin-top: 20px;
    line-height: 21px;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 13px;
    color: #4C4C4C;
}

.kr-result-info {
    width: 100%;
}

.kr-result-info table {
    width: 100%;
    border: 1px solid #BEBEBE;
    margin: 5px 0 0 0;
    padding: 0;
    background-color: white;
    border-collapse: collapse;
}

.kr-result-info table caption {
    justify-content: left;
}

.kr-result-info table {
    color: #010101;
    text-align: left;
    font-size: 12px;
}

.kr-result-info table tr {
    border-bottom: 1px solid #CACACA;
}

.kr-result-info table th {
    padding: 8px 15px;
    font-weight: normal;
    width: 100px;
}

.kr-result-info table th.pending {
    padding: 8px 15px;
    font-weight: normal;
    width: 175px;
}

.kr-result-info h4 {
    color: #858585;
    font-family: "Nanum Gothic", sans-serif;
    letter-spacing: -0.5px;
    padding-left: 5px;
}

.right-side .bottom-line {
    border-top: 1px solid #CACACA;
    margin-top: 20px;
    height: 1px;
    width: 100%;
}

.red {
    color: #DC0000;
}

.big {
    font-size: 90px;
    line-height: 110px;
    letter-spacing: -3px;
}
