.kr-consent-confirm-date {
    line-height: 21px;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 13px;
    font-weight: bold;
    margin-top: 30px;
}

.kr-consent-continue-button {
    height: 45px;
    width: 125px;
    background: white;
    border-radius: 3px;
    color: #686868;
    margin-top: 40px;
    border: 1px solid #CACACA;
    cursor: pointer;
}
