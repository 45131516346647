.left-panel {
  width: 282px;
  min-width: 282px;
  height: auto;
  background-color: #fff;
  border-right: 1px solid #cacaca;
}

.logoRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.logoColumn {
  display: flex;
}

.logoText {
  font-size: 28px;
  color: #878787;
}

.logoText span {
  white-space: nowrap;
}

.logoTextRed {
  color: #BB271B;
}

.left-panel .content {
  padding-top: 30px;
  margin: 0 auto;
  width: 212px;
}

.left-panel ul {
  list-style: none;
  color: #808080;
  margin: 30px 0 20px 0;
  padding: 0;
}

.left-panel ul li {
  border-bottom: 1px solid #bebebe;
  padding: 8px 0;
}

li.email {
  border-top: 1px dotted #bebebe !important;
}

.left-panel ul span {
  margin: 5px 0px;
}

.left-panel ul .email span {
  display: block;
}

.left-panel ul .email .address {
  text-align: right;
}

.left-panel ul .rp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-panel ul .rp span {
  vertical-align: middle;
}

.left-panel ul .rp div {
  color: #ee2e24;
}

.left-panel .amount {
  font-size: 30px;
}

.left-panel .rg-logo {
  position: absolute;
  top: 673px;
}

.left-panel .rp-gift {
  font-size: 16px;
  line-height: 28px;
}

.left-panel .battery {
  padding: 13px 0 5px 0;
}

@media screen and (max-width: 1280px) {
  .left-panel {
    min-width: 225px;
    width: 240px;
  }

  .left-panel .rg-logo {
    top: 580px;
  }
}
