.kr-consent-form-register {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.kr-consent-submit-button {
    width: 110px;
    height: 45px;
    background-color: #EE2E24;
    text-align: center;
    line-height: 45px;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
}

.kr-consent-cancel-button {
    width: 110px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: white;
    border: 1px solid #bebebe;
    margin-left: 10px;
}
