.kr-consent-container {
  background-color: #fafafa;
  height: 850px;
  width: 994px;
  padding: 30px 50px;
  position: relative;
}

.kr-consent-disclaimer {
  width: 100%;
  padding: 25px 0;
  border-top: 1px solid #bebebe;
  text-align: center;
  color: #EE2E24;
  font-weight: bold;
  font-size: 14px;
}

.border-bottom {
  border-bottom: 1px solid #bebebe;
}

.kr-consent-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
}

.kr-consent-section dt {
  width: 132px;
  min-width: 132px;
  border-top: 1px solid #bebebe;
  color: #666666;
  font-weight: bold;
  font-size: 13px;
}

.kr-consent-section dt span {
  display: inline-block;
  padding-top: 10px;
  width: 125px;
}

.kr-consent-section dd {
  width: inherit;
  padding-left: 14px;
  margin: 0;
}

.kr-consent-section-contents {
  border: 1px solid #bebebe;
  border-radius: 4px;
  background-color: #ffffff;
}

.kr-consent-rp-agree-inner {
  padding: 15px 15px 11px 15px;
}

.new-section {
  margin-top: 20px;
}

.kr-consent-agreement-text {
  padding: 20px 0 40px 0;
  margin: 0 0 0 35px;
  width: 100%;
  color: #808080;
  font-size: 12px;
}

.kr-consent-agreement-text li {
  margin-bottom: 5px;
}
